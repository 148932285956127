import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation,
    Pagination,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


const circles = document.querySelectorAll(".circle");

function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return "rgb("+ r + ", " + g + ", " + b + ")";
}

const changeColor = () => {
    const body = document.body;
    const html = document.documentElement;
    const nav = document.querySelector('nav');
    const header = document.querySelector('.header');
    const activeSlide = document.querySelector('.swiper-slide-active');
    const bkgColor = activeSlide.dataset.bkgcolor;
    const menuColor = hexToRgb(activeSlide.dataset.menucolor.replace("#", ""));
    const borderColor = hexToRgb(activeSlide.dataset.bordercolor.replace("#", ""));
    body.style.background = bkgColor;
    html.style.background = bkgColor;

    const menuLinks =   document.querySelectorAll('header nav a');
    menuLinks.forEach((link) =>{
        const linkIntialColor = hexToRgb(link.dataset.color.replace("#", ""));
        const bkgColor = body.style.backgroundColor;
        /*console.log(link);
        console.log("Initial"+linkIntialColor);
        console.log("Menu"+menuColor);
        console.log("Bkg"+bkgColor);*/

        if(linkIntialColor === bkgColor){
            link.style.color = menuColor;
            nav.style.borderColor = borderColor;
        }else{
            link.style.color = linkIntialColor;
        }
    })

}


export const Carousel = () => {

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperH = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard],
            cssMode: false,
            direction: 'horizontal',
            followFinger: false,
            preloadImages: false,
            resizeObserver: false,
            allowTouchMove: false,
            keyboard: {
                enabled: false,
                onlyInViewport: false,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: false,
            },
           /* autoplay: {
                delay: 5000,
            },*/
            watchSlidesProgress: true,
            speed: 300,
            on: {
                init: function () {
                    circles.forEach((circle) =>{
                        circle.addEventListener("mouseenter", () =>{
                            const index = circle.dataset.index;
                            this.slideTo(index, 0);
                            circles.forEach((circle) =>{
                               circle.classList.remove('active');
                            });
                            circle.classList.add('active');
                        });
                        circle.addEventListener("click", () =>{
                            const index = circle.dataset.index;
                            this.slideTo(index, 0);
                            circles.forEach((circle) =>{
                                circle.classList.remove('active');
                            });
                            circle.classList.add('active');
                        });
                        changeColor();
                    });
                },
                slideChangeTransitionStart: function () {
                    changeColor();
                }
            }
        });
    }
    const sliderArticle = document.querySelector(".swiper-container.swiper-article");
    if (sliderArticle) {
        const swiperArticle = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, Pagination],
            cssMode: false,
            direction: 'horizontal',
            followFinger: false,
            preloadImages: false,
            resizeObserver: true,
            allowTouchMove: true,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },

            effect: 'fade',
            fadeEffect: {
                crossFade: false,
            },
            watchSlidesProgress: true,
            speed: 300,
            on: {
                init: function () {
                }
            }
        });
    }
}


Carousel();